

























































import { Vue, Component, Watch } from 'vue-property-decorator';
import {UsuarioService} from '@/core/services/geral';
import {Usuario} from '@/core/models/geral';
import { PageBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimpleErr } from '@/core/services/shared/AlertService';

@Component
export default class ListaUsuario extends PageBase {
  search: any = '';
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Foto', value: 'foto', sortable: false, type: 'null'},
    { text: 'Login', value: 'login' },
    { text: 'Nome', value: 'complementar.nome' },
    { text: 'E-mail', value: 'complementar.email' },
    { text: 'Perfil', value: 'perfil.nome' },
    { text: 'Ativo', value: 'ativo', type: 'boolean'},
  ];
  item = new Usuario();
  service = new UsuarioService();

  @Watch('options', { deep: true })
  Atualizar(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, undefined, "Perfil").then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => (this.loading = false));
  }

  mounted(){
    this.sessionApp = JSON.parse(localStorage.sessionApp);
  }

  AbrirDialogCadastro(item?: Usuario){
    if(item){
      this.service.ObterPorId(item.id, "Perfil, Complementar, Cliente").then(
        res=>{
          this.item = res.data;
          this.dialogCadastro = true;
        },  
        err => AlertSimpleErr("Aviso!", err)
      )
    }
    else{
      this.item = new Usuario();
      this.item.empresaId = this.sessionApp.dados.empresaId;
      this.dialogCadastro = true;
    }
  }
  
  Excluir(item: Usuario){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        await context.service.Excluir(item.id).then(
          res => {
            if(res.status == 200){
              return resolve(res.data);
            }
          },
          err => {
            AlertSimpleErr("Aviso", err);
          }
        ).finally(() => {
          context.Atualizar();
        })
      });
    }
    AlertExcludeQuestion(excluir);
  }
}

